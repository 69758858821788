const ar = {
  'header_component-welcome': 'مرحباً',
  'home_page-contact-us': 'تواصل معنا',
  'home_page-about': 'من نحن',
  'home_page-home': 'الصفحة الرئيسية',
  'home_page-learn-and-support': 'تعلم وساهم',
  'home_page-login': 'تسجيل الدخول',
  'home_page-log-out': 'تسجيل الخروج',
  'home_page-register': 'تسجيل',
  'home_page-no-search-results': 'لا توجد نتائج بحث',
  'contact_us_page-contact-us': 'تواصل معنا',
  'general_text-email': 'البريد الإلكتروني',
  'general_text-name': 'الاسم',
  'general_text-password': 'كلمة المرور',
  'general_text-tags': 'وسوم',
  'general_text-title': 'العنوان',
  'general_text-search': 'بحث',
  'general_text-year': 'سنة',
  'general_text-month': 'شهر',
  'general_text-go': 'اذهب',
  'general_text-save': 'حفظ',
  'general_text-yes': 'نعم',
  'general_text-no': 'لا',
  'general_text-submit': 'إدخـال',
  'general_text-draw': 'إرسـم',
  'calendar_months-january': 'كانون الثاني',
  'calendar_months-february': 'شباط',
  'calendar_months-march': 'آذار',
  'calendar_months-april': 'نيسان',
  'calendar_months-may': 'أيار',
  'calendar_months-june': 'حزيران',
  'calendar_months-july': 'تموز',
  'calendar_months-august': 'آب',
  'calendar_months-september': 'أيلول',
  'calendar_months-october': 'تشرين الأول',
  'calendar_months-november': 'تشرين ثاني',
  'calendar_months-december': 'كانون الأول',
  'calendar_months-number-of-documents': 'عدد الملفات: ',
  'user_sign_in-title': 'تسجيل الدخول',
  'user_sign_in-sign-in': 'دخول',
  'user_sign_in-forget-password': 'نسيت كلمة المرور؟',
  'user_sign_in-input-your-email':'الرجاء إدخال بريدك الإلكتروني',
  'user_sign_in-input-your-password':'الرجاء إدخال كلمة المرور',
  'user_sign_up-title': 'تسجيل مستخدم جديد',
  'user_sign_up-confirm-password': 'تأكيد كلمة المرور',
  'user_sign_up-sign-up': 'تسجيل',
  'user_sign_up-passwords-not-match': 'كلمتا المرور غير متطابقتين',
  'general_text_error-something-went-wrong': 'حدث خطأ ما',
  'user_sign_up-successfully-registered': 'تم التسجيل بنجاح',
  'user_sign_up-please-enter-your-email-address':'الرجاء إدخال عنوان بريدك الإلكتروني',
  'user_sign_up-please-enter-your-password':'الرجاء إدخال كلمة  المرور',
  'user_sign_up-please-enter-your-name':'الرجاء إدخال اسمك',
  'user_sign_up-please-enter-your-password-again':'الرجاء إدخال كلمة المرور مرة أخرى',
  'edit_article_page-edit-mode':'وضع التحرير',
  'edit_article_page-back-to-view-mode':'العودة إلى وضع التصفح',
  'edit_article_page-article-list':'قائمة المقالات',
  'edit_article_page-edit-data':'تحرير البيانات',
  'edit_article_page-layout-number':'قصاصة رقم',
  'edit_article_page-untitled-article':'مقالة بدون عنوان',
  'edit_article_page-mouse-able-mode':'وضع الفأرة',
  'edit_article_page-draw-mode':'وضع الرسم',
  'edit_article_page-delete-layout-message-title':'هل أنت متأكد انك تريد حذف هذه القصاصة؟',
  'edit_article_page-delete-layout-message-info':'اذا كانت اجابتك نعم فلا يمكن التراجع عن هذا القرار',
  'edit_article_page_side_bar_form-type':'النوع',
  'edit_article_page_side_bar_form-main-comment':'ملاحظة رئيسية',
  'edit_article_page_side_bar_form-tag-deleted-successfully':'تم حذف الوسم بنجاح',
  'edit_article_page_side_bar_form-tags':'الوسوم',
  'edit_article_page_side_bar_form-title':'العنوان',
  'edit_article_page_side_bar_form-text':'النص',
  'edit_article_page_side_bar_form-close-for-edit':'أغلق المقالة للتعديلات',
  'edit_article_page_side_bar_form-keep-open-for-edit':'إبقاء مفتوحة للتعديلات',
  'edit_article_page_side_bar_form-ready-for-review':'جاهزة للمراجعة',
  'bread_crumb-home':'الصفحة الرئيسية',
}

export default ar
