const en = {
  'header_component-welcome': 'Welcome',
  'home_page-contact-us': 'Contact us',
  'home_page-about': 'About',
  'home_page-home': 'Home',
  'home_page-learn-and-support': 'Learn & support',
  'home_page-login': 'Login',
  'home_page-log-out': 'Log out',
  'home_page-register': 'Register',
  'home_page-no-search-results': 'No search results',
  'contact_us_page-contact-us': 'Contact us',
  'general_text-tags': 'Tags',
  'general_text-email': 'E-mail',
  'general_text-name': 'Name',
  'general_text-password': 'Password',
  'general_text-title': 'Title',
  'general_text-search': 'Search',
  'general_text-year': 'Year',
  'general_text-month': 'Month',
  'general_text-go': 'Go',
  'general_text-save': 'Save',
  'general_text-yes': 'Yes',
  'general_text-no': 'No',
  'general_text-submit': 'Submit',
  'general_text-draw': 'Draw',
  'calendar_months-january': 'January',
  'calendar_months-february': 'February',
  'calendar_months-march': 'March',
  'calendar_months-april': 'April',
  'calendar_months-may': 'May',
  'calendar_months-june': 'June',
  'calendar_months-july': 'July',
  'calendar_months-august': 'August',
  'calendar_months-september': 'September',
  'calendar_months-october': 'October',
  'calendar_months-november': 'November',
  'calendar_months-december': 'December',
  'calendar_months-number-of-documents': 'Number of documents: ',
  'user_sign_in-title': 'Sign In',
  'user_sign_in-sign-in': 'Sign In',
  'user_sign_in-forget-password': 'Forget Password?',
  'user_sign_in-input-your-email':'Please input your email!',
  'user_sign_in-input-your-password':'Please input your password!',
  'user_sign_up-title': 'Sign Up',
  'user_sign_up-confirm-password': 'Confirm Password',
  'user_sign_up-sign-up': 'Sign Up',
  'user_sign_up-passwords-not-match': 'Passwords do not match',
  'general_text_error-something-went-wrong': 'Something went wrong',
  'user_sign_up-successfully-registered': 'Successfully registered',
  'user_sign_up-please-enter-your-email-address': 'Please enter your email address',
  'user_sign_up-please-enter-your-password': 'Please enter your password',
  'user_sign_up-please-enter-your-name': 'Please enter your name',
  'user_sign_up-please-enter-your-password-again': 'Please enter your password again',
  'edit_article_page-edit-mode': 'Edit Mode',
  'edit_article_page-back-to-view-mode': 'Back to View Mode',
  'edit_article_page-article-list': 'Article List',
  'edit_article_page-edit-data': 'Edit Data',
  'edit_article_page-layout-number': 'Layout Number',
  'edit_article_page-untitled-article': 'Untitled Article',
  'edit_article_page-mouse-able-mode': 'Mouse Able Mode',
  'edit_article_page-draw-mode': 'Draw Mode',
  'edit_article_page-delete-layout-message-title': 'Are you sure you want to delete this layout?',
  'edit_article_page-delete-layout-message-info': 'If your answer is yes, this decision cannot be undone',
  'edit_article_page_side_bar_form-type':'Type',
  'edit_article_page_side_bar_form-main-comment':'Main Comment',
  'edit_article_page_side_bar_form-tag-deleted-successfully':'Tag removed successfully',
  'edit_article_page_side_bar_form-tags':'Tags',
  'edit_article_page_side_bar_form-title':'Title',
  'edit_article_page_side_bar_form-text':'Text',
  'edit_article_page_side_bar_form-close-for-edit':'Close for edits',
  'edit_article_page_side_bar_form-keep-open-for-edit':'Keep open for edits',
  'edit_article_page_side_bar_form-ready-for-review':'Ready for review',
  'bread_crumb-home':'Home',
}

export default en
